import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ArrayParam, NumberParam, QueryParamConfig, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { useAuctionsApi } from '@api/auctions';
import { AuctionsList, AuctionDetail } from './components';
import { AuctionResponse, WebAuctionsFilter } from '@types';

type Props = {
  status: string;
};

export const AuctionsListPage: React.FC<Props> = ({ status }) => {
  const location = useLocation();
  const auctionsApi = useAuctionsApi();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [auctions, setAuctions] = useState<AuctionResponse[]>([]);
  const [totalAuctions, setTotalAuctions] = useState<number>(0);
  const [showFiltersPreloader, setShowFiltersPreloader] = useState(true);
  const [auctionDetailId, setAuctionDetailId] = useState<string | number | undefined>();
  const [auctionDetailType, setAuctionDetailType] = useState<string | undefined>();
  const [query, setQuery] = useQueryParams({
    color: withDefault(StringParam, undefined),
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
    title: withDefault(StringParam, ''),
    priceTo: withDefault(StringParam, ''),
    priceFrom: withDefault(StringParam, ''),
    mainCategory: withDefault(StringParam, '0'),
    auctionType: withDefault(StringParam, ''),
    subCategories: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
    regions: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
    districts: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
    auctioneer: withDefault(StringParam, undefined),
  });

  useEffect(() => {
    setShowFiltersPreloader(true);
    setLoaded(false);
    loadAuctions(query);
    return () => {
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionDetailId]);

  const loadAuctions = async (filterProps?: WebAuctionsFilter) => {
    try {
      const requestProps: WebAuctionsFilter = !!filterProps ? { ...filterProps } : { page: 1, perPage: query.perPage };
      requestProps.mainCategory = requestProps.mainCategory !== '0' ? requestProps.mainCategory : undefined;
      requestProps.perPage = requestProps.perPage || query.perPage;
      requestProps.status = status;
      const response = await auctionsApi.getWebAuctions(
        { ...requestProps },
        status === 'ended' ? 'auction.realEndDt' : 'auction.startDt',
        status === 'ended' ? 'DESC' : 'ASC',
        'getAuctionsCancel'
      );
      setAuctions(response.data.data);
      setTotalAuctions(response.data?.total as number);
      setLoaded(true);
      setShowFiltersPreloader(false);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      setLoaded(true);
    }
  };

  if (!!auctionDetailId && !!auctionDetailType) {
    return (
      <AuctionDetail
        auctionId={auctionDetailId}
        auctionType={auctionDetailType}
        onBack={() => setAuctionDetailId(undefined)}
      />
    );
  }

  return (
    <AuctionsList
      status={status}
      loaded={loaded}
      auctions={auctions}
      auctioneer={query.auctioneer}
      totalAuctions={totalAuctions}
      showFiltersPreloader={showFiltersPreloader}
      query={query}
      setQuery={setQuery}
      onSelect={(item: { id: string | number; type: string }) => {
        setAuctionDetailId(item.id);
        setAuctionDetailType(item.type);
      }}
    />
  );
};
